import React, { useState,useEffect, useRef} from "react";
import { connect } from "react-redux";
import { useCustomQuery, useDeleteMovimiento, useGetBalanceProveedor, useInsertMinorSubject, useInsertMovimiento, useUpdateCompra, useUpdateMovimiento } from "../../Hooks/useApis";
import { resetItemsCash } from "../../redux/Actions";
import { FechaLocalToUTC, FechaUTCToLocal, GetFechaActual, GetFechaActualUTC, getSucursal, moneyToValue, Sucursal,maxlimite, TransformIndex, GetFechaSplit, valueToMoney, getArrayAsync, apiUsePromise } from "../../services/functions";
import InputPrecioPanel from "../InputPrecioPanel";
import LabelFecha from "../LabelFecha";
import Modaldanger from "../modal/danger";
import Modalinfo from "../modal/info";
import LoadingModal from "../modal/LoadingModal";
import TableMovimiento from "./TableMovimiento";
import InputSelectormap from '../Productos/InputSelectormap';
import { useMemo } from "react";
import Labelmoney from "../labelmoney";
import { cloneDeep } from "lodash";
import Modalgeneric from "../modal/generic";
import Modalsuccess from "../modal/success";

const PanelTablaMovimientos = ({ItemsCash,CuentaMayor,setItems,CuentaMenor,FormasPago}) => {
  const [modalinfoConfig, setmodalinfoConfig] = useState({}),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [modalLoading, setmodalLoading] = useState({}),
  [modalArOther,setModalArOther] = useState({}),
  [cuentas,setCuentas]=useState([]),
  [cuentasQuery,setCuentasQuery]=useState([]);
  const item0 = {idCash:"0",type:0,fecha:GetFechaActual().fecha,concepto:"",importe:0,importeIni:0,clave:"",metodo:"",minors:[],cuenta:"",contracuenta:"",descripcion:"",flag:false, edit:false};
  const fechaToday=GetFechaActualUTC();
  const majorIds = Object.keys(CuentaMayor).filter(account=>CuentaMayor[account].bloqueado==false);

  const catalogosMin = useRef([]),
  catalogosMajor =useRef({}),
  elements = useRef({}),

  limite=useRef(maxlimite),
  pagosProveedor=useRef([]),
  auxAccounts = useRef([]),   
  contador=useRef(0),
  cuentaPago=useRef([]),
  suc=useRef([]),
  itemDelete=useRef({}),
  itemUpdate=useRef({}),
  itemInsertDeposit=useRef({}),
  itemDeposit=useRef({}),
  procesoCash=useRef(false);

const calculaLimite=async(item,id)=>{
  const año = GetFechaSplit().año,mes=GetFechaSplit().mes;
  limite.current  = catalogosMajor.current[item.clave].minor.filter(item=>item.id==id)[0].limite ||0;

  const peticion ={
    indexName:"contraCuentaIndex",
    hashKey:{valor:(id+"#"+getSucursal()),nombre:"contracuenta"},
    sortKey:{valor:[FechaLocalToUTC(año+'-'+mes+"-01"),FechaLocalToUTC(GetFechaActual().fecha,23,59,59)],operador:"between",nombre:"type"},
  }
  openmodalLoading();
  try{
    let usado =0
    const datos = await getArrayAsync(peticion,[]);
    datos.map(cash=>{
      if(cash.id=="APOTHOUT#"+getSucursal()){
        usado += cash.importe;
      }
    });
    limite.current = limite.current - (usado*-1);
    closemodalLoading();
    if(itemUpdate.current.importe>limite.current){
      openmodeldanger("El monto excede el limite mensual permitido porfavor cambie el monto Restante : "+valueToMoney(limite.current));
    }  
    itemUpdate.current = {};
    
  }catch (error){
    closemodalLoading();
    handleError(error);
  }
}

const filterCatalogo=(major,catalogMajor)=>{
  let newarray= [];
  if(["AROTHOUT","AROTHIN"].includes(major)){
    newarray = catalogMajor.minor.filter(item=>(!item?.socio)).map(item=>item.id);
  }else{
    newarray = catalogMajor.minor.map(item=>(item.id))
  }
  return newarray;
}

const getBalanceProveedor=async(proveedor)=>{
  const peticion ={ id:proveedor,sucursal:getSucursal()};

  openmodalLoading();
  try{
    const response  = await apiUsePromise ({ url: "/get-balance-proveedor", method: "POST",data: peticion});
    const data = response.data[0];
    
    pagosProveedor.current = [];
    const comprasCredito= data.comprasCredito.sort((fa,fb)=> new Date(fa.id).getTime()-new Date(fb.id).getTime());
    if(!comprasCredito.length){'no hay compras a credito, hay que poner a favor'}
    let restante = ItemsCash.filter(item=>item.idCash==0)[0];
    restante = (restante?.importe?restante.importe:0);
    comprasCredito.forEach(compra=>{
      if(restante<=0){
        //console.log('ya me acabe el total');
        return;
      }
      let porPagar = compra.importe - compra.pagos.reduce((total,pago)=>parseInt(total)+parseInt(pago?.monto),0);
      if(porPagar-restante>=0){
        //console.log(compra?.id,'=>',compra.importe,'=>', porPagar -restante)
        pagosProveedor.current.push({
          id: compra.id,
          sucursal: getSucursal(),
          pagos: [{monto: restante, cuenta:"", metodo:""}],
          lineas: compra?.lineas || [],
          inventarioPendiente: compra.inventarioPendiente,
        });
        restante = 0;
      }
      else{
        pagosProveedor.current.push({
          id: compra.id,
          sucursal: getSucursal(),
          pagos: [{monto: porPagar, cuenta:"", metodo:""}],
          lineas: compra?.lineas || [],
          inventarioPendiente: compra.inventarioPendiente,
        });
        restante = restante -porPagar;
      }
    });
    (restante>0?console.log('todavia hay dinero'):"");
   
    closemodalLoading();
   
  }catch (error){
    closemodalLoading();
    funcionErrorProv(error);
  }
}

  const getMovimientos=()=>{
    auxAccounts.current = [];
    auxAccounts.current.push(item0);
    contador.current = 0;
    cuentaPago.current.map(account=>{
      let peticion ={
        indexName:"cuentaIndex",
        hashKey:{valor:(account+"#"+getSucursal()),nombre:"cuenta"},
        sortKey:{valor:[FechaLocalToUTC(GetFechaActual().fecha),FechaLocalToUTC(GetFechaActual().fecha,23,59,59)],operador:"between",nombre:"type"}
      }
      useCustomQuery(peticion,handleSuccessfulGetMov,handleError);
    });
  }


  const setItem=(item)=>{
    let new_items = [...ItemsCash],cashes=[];
    new_items.map(cash=>{
      if(cash.type==item.type){
        cashes.push(item);
      }else{
        cashes.push(cash);
      }
    })
    setItems(cashes)
  }

  function sendInsert(item){
    if(!item.fecha){ return;}
    if(!item.importe){ openmodeldanger("El campo Importe no valido"); return;}
    if(!item.clave){ openmodeldanger("MajorAccount no valido"); return;}
    if(!item.contracuenta){openmodeldanger("MinorAccount no valido"); return;}
    if(!item.metodo){openmodeldanger("Metodo de pago no valido no valido"); return;}
    if(!item.cuenta){openmodeldanger("Cuenta no valido"); return;}
    if(!item.concepto){ openmodeldanger("Es necesario el campo REMARK"); return;}
    if(item.importe>limite.current){ openmodeldanger("El monto excede el limite mensual permitido porfavor cambie el monto"); return; }  

    openmodalLoading();
    if(item.clave=="DEPOSIT"){
      const newitem= {
        importe:(CuentaMayor[item.clave]?.IO=="O"?item.importe*-1:item.importe),
        concepto:item.concepto,
        clave:item.clave,
        metodo:item.metodo,
        descripcion:item.descripcion,
        cuenta:item.cuenta,
        contracuenta:item.contracuenta,
      }
      itemDeposit.current = {
        importe:(CuentaMayor["DEPOSITIN"]?.IO=="O"?item.importe*-1:item.importe),
        concepto:item.concepto,
        clave:"DEPOSITIN",
        metodo:item.metodo,
        descripcion:item.descripcion,
        cuenta:item.contracuenta,
        contracuenta:item.cuenta,
      };
      useInsertMovimiento(newitem,handleSuccessfulCashInsertDeposit,handleErrorUpdate);
    }else{
      const newitem= {
        importe:(CuentaMayor[item.clave]?.IO=="O"?item.importe*-1:item.importe),
        concepto:item.concepto,
        clave:item.clave,
        metodo:item.metodo,
        descripcion:item.descripcion,
        cuenta:item.cuenta,
        contracuenta:item.contracuenta,
      }
      useInsertMovimiento(newitem,handleSuccessfulCashInsert,handleErrorUpdate);
      elements.current[item.type+"major"].value = "";
      elements.current[item.type+"minor"].value = "";
      elements.current[item.type+"cuenta"].value = "";
      elements.current[item.type+"metodo"].value = "";
      elements.current[item.type+"descripcion"].value = "";
    }
  }

  function sendUpdate(item){
    if(!item.fecha){ return;}
    if(!item.importe){ openmodeldanger("El campo Importe no valido"); return;}
    if(!item.clave){ openmodeldanger("MajorAccount no valido"); return;}
    if(!TransformIndex(item.contracuenta)){openmodeldanger("MinorAccount no valido"); return;}
    if(!TransformIndex(item.metodo)){openmodeldanger("Metodo de pago no valido no valido"); return;}
    if(!TransformIndex(item.cuenta)){openmodeldanger("Cuenta no valido"); return;}
    if(!item.concepto){ openmodeldanger("Es necesario el campo REMARK"); return;}
    if(!item.idCash){openmodeldanger("No editabe"); return;}

    openmodalLoading();
    if(item.importe<0){
      item.importe = item.importe*-1;
    }
    useUpdateMovimiento({
          id:item.type,
          importe:(CuentaMayor[item.clave]?.IO=="O"?(item.importe*-1):item.importe),
          concepto:item.concepto,
          clave:item.clave,
          metodo:TransformIndex(item.metodo),
          descripcion:item.descripcion,
          cuenta:TransformIndex(item.cuenta),
          contracuenta:(TransformIndex(item.contracuenta)=="SYS"?"SYS#"+getSucursal():TransformIndex(item.contracuenta))
    },handleSuccessfulCashUpdate,handleErrorUpdate);
  }



  const handleSuccessfulCashUpdate=(data)=>{
    let new_items = [...ItemsCash].filter(cash=>cash.type!==itemUpdate.current.type);
    new_items.push(itemUpdate.current);

    setItems(new_items);
    closemodalLoading();
    itemDelete.current={};
    limite.current = maxlimite;
    itemUpdate.current = {};
  }
  const handleSuccessfulCashInsert=(data)=>{
    let new_items = [...ItemsCash].sort((fa,fb)=> new Date(fa.type).getTime()-new Date(fb.type).getTime());
    const cash = data.items;
    const newcash = {...cash};
    new_items.shift();
    new_items.push(item0);

    newcash.idCash = cash.id;
    newcash.fecha=FechaUTCToLocal(cash.type).fecha;
    newcash.metodo=cash.metodo.substring(cash.metodo.indexOf("",0),cash.metodo.lastIndexOf("#")),
    newcash.edit=false;
    newcash.importeIni=(CuentaMayor[cash.clave]?.IO=="O"?cash.importe*-1:cash.importe);
    delete newcash.id;
    if(JSON.stringify(itemInsertDeposit.current)!=="{}"){
      new_items.push(itemInsertDeposit.current);
    }
    new_items.push(newcash);
 
    setItems([...new_items].sort((fa,fb)=> new Date(fa.type).getTime()-new Date(fb.type).getTime()));
    closemodalLoading();
    itemDeposit.current={};
    itemInsertDeposit.current=={};
    limite.current = maxlimite;
    itemUpdate.current = {};
    
    procesoCash.current = false;
  }
  const handleSuccessfulCashInsertDeposit=(data)=>{
    const cash = data.items;
    const newcash = {...cash};
    itemDeposit.current = {...itemDeposit.current, descripcion: cash.type}

    newcash.idCash = cash.id;
    newcash.fecha=FechaUTCToLocal(cash.type).fecha;
    newcash.metodo=cash.metodo.substring(cash.metodo.indexOf("",0),cash.metodo.lastIndexOf("#")),
    newcash.edit=false;
    newcash.importeIni=(CuentaMayor[cash.clave]?.IO=="O"?cash.importe*-1:cash.importe);
    delete newcash.id;

    itemInsertDeposit.current = newcash;
    useInsertMovimiento(itemDeposit.current,handleSuccessfulCashInsert,handleError);
  }
  const handleSuccessfulCashDelete=(data)=>{
    let new_items = [];
    if(itemDelete.current.clave=="DEPOSIT"){
      const del = ItemsCash.filter(cash=>cash.descripcion==itemDelete.current.type)[0];
      itemDeposit.current = del;
      useDeleteMovimiento(del.type,del.clave,del.concepto,handleSuccessfulCashDeleteDeposit,handleErrorDelete);
      return;
    }
    ItemsCash.map(cash=>{
      if(cash.type!==itemDelete.current.type){
        new_items.push(cash)
      }
    })

    setItems(new_items);
    closemodalLoading();
    itemDelete.current={};
  }
  const handleSuccessfulCashDeleteDeposit=(data)=>{
    let new_items = [];

    ItemsCash.map(cash=>{
      if(cash.type!==itemDelete.current.type&&cash.type!==itemDeposit.current.type){
        new_items.push(cash)
      }
    })

    setItems(new_items);
    closemodalLoading();
    itemDelete.current={};
    itemDeposit.current={};
  }

  const closeModalsuccess=()=>{
    setmodalSuccessConfig({isOpen:false});
  }
  
  const handleError = (error) => {
    openmodeldanger(error?.response?.data?.message||error?.message||error||"No se pudo cargar Los Movimientos porfavor vuelve a cargar la pagina")
  }
  const handleErrorUpdate = (error) => {
    setmodaldangererrorConfig({
      isOpen: true,
      message: "No se pudo Actualizar el movimiento Intentelo nuevamente porfavo "+error ,
    });
  }
  const handleErrorDelete = (error) => {
    closemodalLoading();
    setmodaldangererrorConfig({
      isOpen: true,
      message: "No se pudo Eliminar el movimiento Intentelo nuevamente porfavor " ,
    });
  }
  const handleSuccessfulGetMov=(data)=>{

    if(data.datos.length>0){
      data.datos.map(cash=>{
        const newcash = {...cash};
        newcash.idCash = cash.id;
        newcash.fecha=FechaUTCToLocal(cash.type).fecha;
        newcash.metodo=cash.metodo,
        newcash.edit=false;
        newcash.importeIni=(CuentaMayor[cash.clave]?.IO=="O"?cash.importe*-1:cash.importe);
        delete newcash.id;
        auxAccounts.current.push(newcash);
      })
    }
    contador.current = contador.current + 1;
    if(contador.current>=cuentaPago.current.length){
      const new_items = [...auxAccounts.current].sort((fa,fb)=> new Date(fa.type).getTime()-new Date(fb.type).getTime());
      setItems(new_items);
      closemodalLoading();
    }
  }

  const funcionErrorProv=(error)=>{
    console.log('error=>',error)
  }
  const handleSuccessfulPagar=(data)=>{
    console.log('pago exitoso')
  }
  const handleErrorPagar=(error)=>{
    console.log('pago malo')
  }

  const openmodalArOther=(valor,item)=>{
    setModalArOther({isOpen:true, valor:valor||"S/C",item:item});
  }
  const closemodalArOther=()=>{
    setModalArOther({isOpen:false});
  }

  function closeModalinfo(e) {
    setmodalinfoConfig({ isOpen: false });
  }
  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };
  const openmodeldanger=(mensaje)=>{
    procesoCash.current = false;
    setmodaldangererrorConfig({
      isOpen: true,
      message: mensaje ||"error",
    });
  };
  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }

  
  useMemo(()=>{
    if(!cuentasQuery.length){return}
    openmodalLoading();
    getMovimientos();
  },[cuentasQuery])

  useEffect(() => {
    const getCatalogos=async()=>{
      const peticiones={
        user:{ indexName:"reverseIndex",hashKey:{valor:"USER",nombre:"type"},filters:[{nombre:"activo",valor:true,operador:"eq"},{nombre:"sucursal",valor:getSucursal(),operador:"eq"}] },
        socio:{ indexName:"reverseIndex",hashKey:{valor:"USER",nombre:"type"},filters:[{nombre:"activo",valor:true,operador:"eq"},{nombre:"socio",valor:true,operador:"eq"}] },
        people:{ indexName:"asociadoIndex",hashKey:{valor:"PEOPLE#"+getSucursal(),nombre:"sujetoAsociado"}},
        sucursal:{ indexName:"reverseIndex",hashKey:{valor:"SUCURSAL",nombre:"type"} },//,sortKey:{valor:getSucursal(),operador:"gte",nombre:"id"}
        proveedor:{ indexName:"reverseIndex",hashKey:{valor:"SUPPLIER",nombre:"type"},filters:[{nombre:"activo",valor:true,operador:"eq"}] },
        cliente:{indexName:"reverseIndex",hashKey:{valor:"CLIENT",nombre:"type"}, filters:[{nombre:"sucursal",valor:getSucursal(),operador:"eq"},{nombre:"activo",valor:true,operador:"eq"}]}
      },
      accountMajors = cloneDeep(CuentaMayor),
      accountMinors={...CuentaMenor};
      Object.keys(accountMinors).forEach(account=>{
        const newCatalog = accountMinors[account].map(item=>{ return {id:item, nombre:item}})
        accountMinors[account] =newCatalog;
      })
      accountMinors.USER = [];accountMinors.SOCIO=[];accountMinors.PEOPLE=[];accountMinors.SUCURSAL=[];accountMinors.SUPPLIER=[];accountMinors.CLIENT=[];

      
      try{
        openmodalLoading();
        const users = await getArrayAsync(peticiones.user,[]);
        const socios =  await getArrayAsync(peticiones.socio,[])
        const peoples = await getArrayAsync(peticiones.people,[]);
        const sucursales = await getArrayAsync(peticiones.sucursal,[]);
        const suppliers = await getArrayAsync(peticiones.proveedor,[]);
        const clients = await getArrayAsync(peticiones.cliente,[]);

        users.forEach(user=>{ accountMinors.USER.push({id:user.id, nombre:user.nombre, sueldo:user.sueldo, socio:user.socio, type:"USER"}) })
        peoples.forEach(people=>{ accountMinors.PEOPLE.push({id:people.id, nombre:people.nombre, type:"PEOPLE"}) });
        sucursales.forEach(sucursal=>{ accountMinors.SUCURSAL.push({id:sucursal.id, nombre:sucursal.nombre, type:"SUCURSAL"}) });
        suppliers.forEach(prov=>{ accountMinors.SUPPLIER.push({id:prov.id, nombre:prov.nombre, limite:(prov.creditoSucursales[getSucursal()] || 0), type:"SUPPLIER"})  });
        clients.forEach(client=>{ accountMinors.CLIENT.push({id:client.id, nombre:client.nombre, limite:client.limiteCredito, type:"CLIENT"}) });
        socios.forEach(socio=>{
          if(socio?.limite[getSucursal()]&&socio.activo){
            accountMinors.SOCIO.push({id:socio.id, nombre:socio.nombre, limite:socio.limite[getSucursal()].limite||0, type:"SOCIO"})
          }
        });

        Object.keys(accountMajors).forEach(key=>{
          accountMajors[key].minor=[];
          accountMajors[key]?.minoraccount?.forEach(account=>{
            accountMajors[key].minor = accountMajors[key].minor.concat(accountMinors[account])
          })       
        })
       
        catalogosMin.current = accountMinors;
        catalogosMajor.current = accountMajors;

        closemodalLoading();


        const accounts =  Sucursal('cuentas');
        let sucu = [],cuentasAux=[];
        if(cuentaPago.current?.length){cuentaPago.current=[];}
        cuentaPago.current.push("CAJA");
        cuentasAux.push("CAJA");
        accounts.map(met=>{
          Object.keys(met).map(cuenta=>{
            cuentaPago.current.push(cuenta+"/"+met[cuenta]);
            cuentasAux.push(cuenta+"/"+met[cuenta]);
            met.id= cuenta+"/"+met[cuenta];
          })
        })
        cuentaPago.current.map(account=>{
          sucu.push({catalogo:"DEPOSIT",valor:account})
        })
        suc.current = sucu;
        setCuentasQuery(cuentasAux);
        setCuentas(accounts)
      }catch (error){
        closemodalLoading();
        handleError(error);
      }      
    }

    getCatalogos();

  }, [])
  
  return (
    <>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cargado Movimientos</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>

      
      <Modalsuccess config={modalSuccessConfig} tab="100" closeModal={closeModalsuccess}>
        <Modalsuccess.Message title={modalSuccessConfig.title} message={modalSuccessConfig.message}>
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            className="btn-green"
            name="modalsuccess"
            tabIndex={"101"}
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>

      <Modaldanger config={modaldangererrorConfig}>
            <Modaldanger.Message title={"Error"}>
            {modaldangererrorConfig.message}
            </Modaldanger.Message>
            <Modaldanger.Buttons>
            <button
                type="button"
                className="btn-gray2 "
                onClick={closeModaldangererror}
            >
                Cerrar
            </button>
            </Modaldanger.Buttons>
        </Modaldanger>

      <Modalinfo config={modalinfoConfig}>
        <Modalinfo.Message title={"Cantidad no válida"}>
          {modalinfoConfig.message}
        </Modalinfo.Message>
        <Modalinfo.Buttons>
          <button
            type="button"
            className="btn-blue1"
            onClick={closeModalinfo}
          >
            Cerrar
          </button>
        </Modalinfo.Buttons>
      </Modalinfo>

      <Modalgeneric config={modalArOther} h="size-h-20" tab="200" closeModal={closemodalArOther}>
        <Modalgeneric.Body >
          <Modalgeneric.Title >AROTHER no dado de alta</Modalgeneric.Title>
          <div className="w-full flex flex-col items-center">
            <label >{"Desea dar de alta el codigo "+modalArOther.valor+"? Antes verifique que no este mal escrito el codigo"} </label>
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons>
          <button
            type="button"
            onClick={closemodalArOther}
            name="modalgeneric"
            className="btn-gray2"
            tabIndex="200"
          >
            Cancelar
          </button>
          <button
          type="button"
          tabIndex="201"
          name="modalgeneric"
          onClick={async(e)=>{
            const valor = getSucursal().substring(0,2)+modalArOther.valor
           const peticion = {
            id: valor,
            type: "PEOPLE",
            sucursal : getSucursal(),
            nombre: modalArOther.valor
           }

           try{
            openmodalLoading();
            /*const respUser = await getArrayAsync({
              hashKey:{valor:valor,nombre:"id"},
              sortKey:{valor:"PEOPLE",nombre:"type",operador:"eq"},
              filters:[{valor:getSucursal(),nombre:"sucursal",operador:"eq"}]
            },[]);
            const respSubject = await getArrayAsync({
              hashKey:{valor:valor,nombre:"id"},
              sortKey:{valor:"USER",nombre:"type",operador:"eq"},
              filters:[{valor:getSucursal(),nombre:"sucursal",operador:"eq"}]
            },[])
            
            if(respUser.length!=0&&respSubject.length!==0){
              openmodeldanger("EL usuario que intenta ingresar ya existe");
              return
            }*/
            closemodalArOther();
             const respuesta = await useInsertMinorSubject(peticion);
             catalogosMin.current.PEOPLE.push({id:valor,nombre:modalArOther.valor,type:"PEOPLE"});
             catalogosMajor.current.AROTHIN.minor.push({id:valor,nombre:modalArOther.valor,type:"PEOPLE"});
             catalogosMajor.current.AROTHOUT.minor.push({id:valor,nombre:modalArOther.valor,type:"PEOPLE"});

             const new_item = {...modalArOther.item, minors:filterCatalogo("AROTHOUT",catalogosMajor.current["AROTHOUT"])};
             setItem(new_item);
             closemodalLoading();
             setmodalSuccessConfig({
              isOpen: true,
              title:  "Exito",
              message: respuesta?.data?.message,
            });

           }catch (error){
            closemodalLoading();
            handleError(error);
           }
          }}
          className={"btn-green1"}
          >
            Agregar
          </button>
        </Modalgeneric.Buttons>
      </Modalgeneric>
      <TableMovimiento
        searchinput={false}
        columns={[
          {
            header: "Date",
            width:"w-32 ",
                        // eslint-disable-next-line react/display-name
            code: (item,index) => (
              <div className="flex w-full flex-row">
                <div className={"flex group justify-end w-20 "}>
                  <span className="tooltiptext-free bg-gray-500 text-white ">{FechaUTCToLocal(item?.type).hora}</span>
                  <LabelFecha className={"text-size font-semibold w-full"} hour={true}>{item?.type||fechaToday}</LabelFecha>
                </div>
                <div className="flex justify-between gap-2">
                  <div 
                  className=" rounded w-3 h-3" 
                  hidden={item?.type==0?true:(item?.fecha!==GetFechaActual().fecha?true:(!item?.personal?true:(item?.clave=="DEPOSITIN"?true:false)))}
                  onClick={e=>{  if(e.detail==2){
                    openmodalLoading();
                    itemDelete.current = {...item};
                    useDeleteMovimiento(item.type,item.clave,item.concepto,handleSuccessfulCashDelete,handleErrorDelete);
                  }
                  }}>
                    <i  className={"fas fa-minus-circle transform duration-300 hover:text-red-500 hover:scale-110 text-red-600"}></i>
                  </div>
                  <div 
                  className=" rounded w-3 h-3 " 
                  hidden={item?.type==0?true:(item?.fecha!==GetFechaActual().fecha?true:(!item?.personal?true:(item?.clave=="DEPOSITIN"?true:false)))}
                  onClick={e=>{ 
                    if(e.detail==2){
                      console.log(item)
                      setItem({...item,
                        metodo:TransformIndex(item.metodo),
                        cuenta:TransformIndex(item.cuenta),
                        edit:!item.edit
                      });

                    } 
                  }}>
                    <i  className={"fas fa-edit transform duration-300 hover:text-teal-500 hover:scale-110 text-green-600"}></i>
                  </div>
                </div>
            </div>
            ),
          },
          {
            header: "Cat",
            width:"w-20",
            // eslint-disable-next-line react/display-name
            code: (item,index) => (<label className="border-table w-full text-center">{item?.concepto||""}</label>),
          },
          {
            header: "AMOUNT",
            width:"w-28",
            // eslint-disable-next-line react/display-name
            code: (item,index) => (
              item?.type==0||item?.edit==true?
                <div className={"w-full "+(item.edit?"bg-sky-100":"")}>
                  <InputPrecioPanel
                  width="w-full"
                  max={(item.type==0?limite.current:maxlimite)}
                  min={0}
                  inputRef={node=>{elements.current[item?.type+"importe"]=node}}
                  reset={item?.flag}
                  newValor={(item?.importeIni?item?.importeIni:(item?.flag==false?0:item?.importeIni))}
                  disabled={item.type!==0?!item.edit:false}
                  setValor={(e)=>{ 
                    const new_item = {...item,importe:moneyToValue(e)};
                    if(item?.type=="0"){new_item.flag=true}
                    setItem(new_item);
                  }}
                  onKeyPress={e=>{
                    /*const input = elements.current[item?.type+"importe"];
                    if(input.selectionStart === 0 && input.selectionEnd === input.value.length){
                      if(e.key=="ArrowRight"){ elements.current[item?.type+"major"].focus();  }
                    }*/
                  }}
                  />
                </div>
                :
                <Labelmoney twoDecimal={true} className="text-size font-semibold" >{item?.importeIni}</Labelmoney>
            ),
          },
          {
            header: "Major",
            width:"w-32",
            // eslint-disable-next-line react/display-name
            code: (item,index) => (
            item?.type==0||item?.edit==true?
              <InputSelectormap
              table={true}
              name={item?.type+"major"}
              inputRef={node=>{elements.current[item?.type+"major"]=node}}
              defaultValue={item?.clave}
              catalogo={majorIds}
              reset={item?.flag}
              readOnly={item.type!==0?true:false}
              onBlurI={(valor=>{
                if(!valor?.clave){limite.current = maxlimite; setItem({...item,clave:"",contracuenta:"",concepto:"",minors:[]});return;}
                const new_item = {...item, clave:valor.valor, concepto:catalogosMajor.current[valor.valor].concepto, minors:filterCatalogo(valor.valor,catalogosMajor.current[valor.valor])};
                setItem(new_item);
              })}
              onKeyPress={e=>{
                /*const input = elements.current[item?.type+"major"];
                if(input.selectionStart === 0 && input.selectionEnd === input.value.length){
                  if(e.key=="ArrowLeft"){ elements.current[item?.type+"importe"].focus(); elements.current[item?.type+"importe"].select(); }
                  if(e.key=="ArrowRight") elements.current[item?.type+"minor"].focus();
                  
                }*/
              }}
              />
              :
              <p className="w-full text-size font-semibold">{item?.clave}</p>
            ),
          },
          {
            header: "Minor",
            width:"w-32",
            // eslint-disable-next-line react/display-name
            code: (item,index) => (
            item?.type==0?
              <InputSelectormap
              table={true}
              name={item?.type+"minor"}
              defaultValue={item?.contracuenta}
              inputRef={node=>{elements.current[item?.type+"minor"]=node}}
              reset={item?.clave}
              catalogo={item?.minors}
              readOnly={item.type!==0?true:false}
              onBlurI={(valor) => {
                const new_mov = {...item};
                if(!valor?.clave){
                  if(item?.clave=="AROTHOUT"&&valor?.valor!==""){
                    openmodalArOther(valor?.valor,item);
                  }
                  limite.current = maxlimite;
                  new_mov.contracuenta = "";
                }else{
                  const contraCuenta = valor.valor;
                  delete new_mov.metodoIni;

                  new_mov.contracuenta = contraCuenta;

                  if(item?.clave=="APOTHOUT"){
                    itemUpdate.current = {...item};
                    calculaLimite(item,contraCuenta);
                  }else if(item?.clave=="APOTHIN"){
                    itemUpdate.current = {...item};
                  }else if (item?.clave=="AP") {
                    pagosProveedor.current = [];
                    //getBalanceProveedor(contraCuenta);
                    //useGetBalanceProveedor(contraCuenta,funcionExitoProv,funcionErrorProv);
                  }else if(item?.clave=="PAYROLL"){
                    const userExists = catalogosMajor.current[item.clave].minor.filter(item=>item.id==contraCuenta)[0].sueldo ||0;
                    if(userExists){
                      new_mov.importeIni=userExists;
                      new_mov.metodoIni="EFECTIVO";
                    }
                  }
                }
                setItem(new_mov);
              }}
              onKeyPress={e=>{
               /* const input = elements.current[item?.type+"minor"];
                if(input.selectionStart === 0 && input.selectionEnd === input.value.length){
                  if(e.key=="ArrowLeft") elements.current[item?.type+"major"].focus();
                  if(e.key=="ArrowRight") elements.current[item?.type+"metodo"].focus();
                }*/
              }}
              />
              :
              <input className="w-full font-semibold border-table " readOnly={true} value={TransformIndex(item?.contracuenta)}/>
            ),
          },
          {
            header: "Metodo",
            width:"w-36",
            // eslint-disable-next-line react/display-name
            code: (item,index) => (
            item?.type==0||item?.edit==true?
            <div className="flex flex-row w-full">
              <InputSelectormap
              table={true}
              className={(item.edit?"bg-sky-100":"")}
              name={item?.type+"metodo"}
              inputRef={node=>{elements.current[item?.type+"metodo"]=node}}
              reset={item?.flag}
              defaultValue={item?.metodo}
              newValor={item?.metodoIni}
              catalogo={FormasPago}
              readOnly={item.type!==0?!item.edit:false}
              onBlurI={(valor) => {
                if(!valor?.clave){
                  const new_item = {...item,metodo:""};
                  elements.current[item.type+"cuenta"].value = "";
                  delete new_item.cuentablock;
                  setItem(new_item);
                  return;
                }
                const new_item = {...item,metodo:valor.valor};
                if(valor.valor=="EFECTIVO"){
                  elements.current[item.type+"cuenta"].value = "CAJA";
                  new_item.cuenta = "CAJA"; 
                  new_item.cuentablock=true;
                }else{
                  elements.current[item.type+"cuenta"].value = "";
                  new_item.cuenta = ""; 
                  delete new_item.cuentablock;
                }
                setItem(new_item);
              }}
              />
            </div>
            :
            <input className="w-full font-semibold border-table " readOnly={true} value={TransformIndex(item?.metodo)}/>
            ),
          },
          {
            header: "Cuenta",
            width:"w-48",
            // eslint-disable-next-line react/display-name
            code: (item,index) => (
              item?.type==0||item?.edit==true?
                <InputSelectormap
                className={"flex flex-row w-full "+ (item.edit?"bg-sky-100":"")}
                table={true}
                reset={item?.flag}
                name={item?.type+"cuenta"}
                defaultValue={item?.cuenta}
                inputRef={node=>{elements.current[item?.type+"cuenta"]=node}}
                catalogo={cuentaPago.current}
                disabled={item.type!==0?!item.edit:(item?.cuentablock==true?true:false)}
                onBlurI={(valor) => {
                  if(!valor?.clave){setItem({...item,cuenta:""});return;}
                  setItem({...item,cuenta:valor.valor});
                }}
                />
              :
              <input className="w-full font-semibold border-table " readOnly={true} value={TransformIndex(item?.cuenta)}/>              
            ),
          },
          {
            header: "Remark",
            width:"w-96",
            // eslint-disable-next-line react/display-name
            code: (item,index) => (
              <div className="flex flex-row w-full">
              {item?.type==0||item?.edit==true?
               <input
                type="text"
                className={"font-semibold bg-transparent border-0 rounded outline-none focus:outline-none disabled:opacity-50 w-full uppercase text-sm "+ (item.edit?"bg-sky-100":"")}
                readOnly={item.type!==0?!item.edit:false}
                defaultValue={item?.descripcion}
                ref={node=>{elements.current[item?.type+"descripcion"]=node}}
                onKeyDown={e=>{
                  const val = e.target.value.trim().toUpperCase();
                  if(e.key=="Enter"||e.key=="Tab"){
                    if(procesoCash.current ==true){return}
                    procesoCash.current = true;
                    const new_item = {...item,descripcion:val}
                    if(item.type==0){
                        setItem(new_item)
                        sendInsert(new_item);
                    }else{
                      if(!item.edit){ return;}

                      itemUpdate.current = {...item,descripcion:val,importeIni:(item.importe<0?(item.importe*-1):item.importe),edit:false};
                      sendUpdate({...item,descripcion:val});
                    }
                  }
                }}
              />
              :<label className="uppercase text-xs font-semibold">{item?.descripcion}</label>
              }
            </div>
            ),
          },
        ]}
        data={ItemsCash}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  resetCash:()=>dispatch(resetItemsCash()),
  setItems:(items)=>dispatch({type:"SET_ITEMS_CASH",items:items}),
});

const mapStateToProps = (state) => ({
  ItemsCash: state.itemsCash,
  CuentaMayor:state.Catalogos.MAJORACCOUNTS,
  CuentaMenor:state.Catalogos.MINORACCOUNTS,
  FormasPago: state.Catalogos.FORMASPAGO.opciones.SS,
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelTablaMovimientos);
