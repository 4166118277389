import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useGetPersonal, useSetSucursal } from "../Hooks/useApis";
import { setRuta, setLogueo, returnRuta } from "../redux/Actions";
import { GetFechaActualUTC, IdToShortId, RestaMinutos, Sucursal, apiUse, decryptData, encryptData, getCookieValue, getIdUser, getSucursal, havePermission, moneyToValue, shortIdToId } from "../services/functions";
import Modalgeneric from "./modal/generic";
import LoadingModal from "./modal/LoadingModal";
import Modalsuccess from "./modal/success";
import Modaldanger from "./modal/danger";
import {motion} from 'framer-motion'
import Newlogo from "../static/img/logonew";
import CalendarModal from "./modal/CalendarModal";
import { permisos } from "../static/dat/datos";
import ModalReportes from "./modal/ModalReportes";
import { btnScale, btnScale8, btnTap } from "../static/motionStyle";
import Toggle from "../components/Toggle.jsx";
import LogoFestivo from '../static/img/logofestivo';

const Navbar=(props)=> {
  const [navbarOpen, setNavbarOpen] = useState(false),
  [showNavbar,setShowNavbar] = useState(true),
  [showPass,setShowPass] = useState(false),
  [showPass2,setShowPass2] = useState(false),
  [password,setPassword] = useState(""),
  [password2,setPassword2] = useState(""),
  [id,setId] = useState(""),
  [shortId,setShortId]=useState(false),
  [error,setError] = useState(false),
  [modalLoading, setmodalLoading] = useState({}),
  [modaldangererrorConfig, setmodaldangererrorConfig] = useState({}),
  [modalSuccessConfig, setmodalSuccessConfig] = useState({}),
  [openModalCalendar,setOpenModalCalendar]=useState(false),
  [modalItem, setmodalItem] = useState({}),
  [modalExpireSession,setModalExpireSession]=useState({}),
  [modalShortId, setmodalShortId] = useState({}),
  [modalReports,setModalReports] = useState({});
  const input_pass = useRef(),input_sucursales = useRef(),inputShortId=useRef("");

  const usuario = props.Logueo.nombre;
  const permisoAltaCliente = havePermission(permisos.altaCliente),
  permisoAltaProveedor = havePermission(permisos.altaProveedor),
  permisoAltaPersonal = havePermission(permisos.regUsuario),
  permisoVender = havePermission(permisos.vender),
  permisoSuper = havePermission(permisos.configurar),
  permisoReporte =  havePermission(permisos.reportes);

  const rutaActual =props.Ruta?.[props.Ruta.length-1];

  const handleKeyPress=(e)=>{
    if(e.key=="F5"){ e.preventDefault();  }
    if(e.key=="v"&&permisoVender){ props.addRuta("venta");}
    if(e.key=="c"&&permisoAltaCliente){props.addRuta("clientes");}
    if(e.key=="p"&&permisoAltaProveedor){ props.addRuta("proveedor");}
    if(e.key=="r"&&permisoAltaPersonal){ props.addRuta("personal");}
    if(e.key=="5"&&permisoReporte){ openmodalReports();}
  }


  const funcionExito=(resp)=>{
    const personal = resp[0];
    if(password!==decryptData(personal.password)){
      setError(true);
    }else{
      openmodalLoading();
      personal.password = encryptData(password2);
      apiUse({
        url: "/update-personal",
        method: "POST",
        data: personal,
        handleSuccessful: funcionExitoPass,
        handleError: funcionError,
      });
      setError(false);
    }
  }
  const funcionErrorPersonal=(error)=>{
    closemodalItem();
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.message || error?.response?.data?.message || "Antes de cambiar una contraseña desbes cambiarte a tu sucursal",
    });
  }
  const funcionError=(error)=>{
    //console.log('==>',error)
    closemodalLoading();
    setmodaldangererrorConfig({
      isOpen: true,
      message: error?.message || error?.response?.data?.message || "Antes de cambiar una contraseña desbes cambiarte a tu sucursal",
    });
  }
  const funcionExitoPass=()=>{
    closemodalItem();
    closemodalLoading();
    setmodalSuccessConfig({
      isOpen: true,
      message: "La actualización se realizó con éxito",
    });
  }

  const openmodalReports=()=>{
    setModalReports({isOpen:true});
  }
  const closeModalReports=()=>{
    setModalReports({isOpen:false});
  }
  const openmodalShortId = (e) => {
    setmodalShortId({ isOpen: true });
  };
  const closemodalShortId = (e) => {
    setId("");
    setmodalShortId({ isOpen: false });
  };
  const openmodalItem = (e) => {
    setmodalItem({ isOpen: true });
  };
  const closemodalItem = (e) => {
    setPassword("");
    setPassword2("");
    setError(false);
    setmodalItem({ isOpen: false });
  };
  const openmodalLoading = (e) => {
    setmodalLoading({ isOpen: true });
  };
  const closemodalLoading = (e) => {
   setmodalLoading({ isOpen: false });
  };
  function closeModalsuccess(e) {
    const newdataconfig = { ...modalSuccessConfig };
    newdataconfig.isOpen = false;
    setmodalSuccessConfig(newdataconfig);
    logOut();
  }
  function closeModaldangererror(e) {
    const newdataconfig = { ...modaldangererrorConfig };
    newdataconfig.isOpen = false;
    setmodaldangererrorConfig(newdataconfig);
  }

  const logOut = () => {
    document.cookie = "sessionIndigo=; max-age=0; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    localStorage.clear();
    props.addRuta("login");
    props.setLogueo({});
    localStorage.setItem("sucursal", JSON.stringify({}));
    setNavbarOpen(!navbarOpen);
    ResetRedux();
  };


  function ResetRedux() {
    props.resetitemsVenta();
    props.resetClientes();
    props.resetClientesVenta();
    props.resetSearchPersonal();
    props.resetSearchProveedor();
    props.resetHeaderCompra();
    props.resetitemsCompra();
    props.resetHeaderVenta();
    props.resetVentaAPagar();
    props.resetTicket();
    props.resetventasCredito();
    props.resetInventario();
    props.resetPropsInventario();
    props.resetitemsPedidoEspecial();
    props.resetitemsCash();
    props.resetPropsReportes();
    props.resetDatos();
  }

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0,y :"-50%"},
  }

  useEffect(() => {
    if(!props.Datasucursal){return}
    if(!getCookieValue("sessionIndigo")){return}
    if(getSucursal()!==props.Logueo?.sucursal){
      let color = "bg-bluei",textcolor="text-bluei";

      if(getSucursal()!==props.Logueo?.sucursalOrigen){
        color = "bg-second";
        textcolor = 'text-second';
      }
      props.setLogueo({...props.Logueo,bgcolor:color,textcolor:textcolor, sucursal: getSucursal()});
      props.addRuta("menu");
      ResetRedux();
      input_sucursales.current.value = getSucursal();
    
    }
  },[props.Datasucursal])

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'sucursal') {
        let ruta = 'menu';
        if(event.newValue=="{}"){ ruta='login' }
        else{
          const login = JSON.parse(localStorage.getItem("usuario")),
          sucursal = JSON.parse(event.newValue);

          let color = "bg-bluei",textcolor="text-bluei";

          if(sucursal.id!==props.Logueo?.sucursalOrigen){
            color = "bg-second";
            textcolor = 'text-second';
          }
          login.bgcolor = color;
          login.textcolor = textcolor;
          props.setLogueo(login);
          props.setDataSucursal(sucursal)
          input_sucursales.current.value = getSucursal();
        }
        props.addRuta(ruta);
        ResetRedux();
      }
    };

    // Agregar el event listener para el evento 'storage'
    window.addEventListener('storage', handleStorageChange);

    // Eliminar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  
  useEffect(() => {
    if(modalItem?.isOpen==true){
      input_pass?.current?.focus();
    }
  }, [modalItem?.isOpen])

  useEffect(() => {
    input_sucursales?.current?.focus();
  },[])

  useEffect(()=>{
    if(props.Logueo.windowSize<768){setShowNavbar(false);}
    else{setShowNavbar(true)}
  },[props.Logueo.windowSize])

  useEffect(()=>{
      if(!getIdUser()){ return;}
      const diferenciaMinutos =RestaMinutos(props.Logueo.validacion,GetFechaActualUTC());

      if(diferenciaMinutos>0&&diferenciaMinutos<=30){
        if(modalExpireSession?.isOpen==true){return}
        setModalExpireSession({isOpen:true})
        return
      }
  })


  return (
    <nav tabIndex="500" className={" sticky top-0 z-20 w-full  flex flex-wrap items-center justify-between px-2 navbar-expand-lg "+props.Logueo?.bgcolor}
    onClick={e=>{
      if(modalItem?.isOpen||modalShortId?.isOpen||modaldangererrorConfig?.isOpen||modalReports?.isOpen){return}
        input_sucursales?.current?.focus();
    }} 
    onKeyDown={handleKeyPress} >
      {modalExpireSession?.isOpen==true?
      <motion.div 
      initial={{opacity:0,y:"-100%"}}
      variants={{open:{ opacity: 0.8,y : "150%"}, closed:{ opacity: 0,y :"-100%"}}}
      animate={modalExpireSession?.isOpen==true?"open":"closed"}
      transition={{ duration: 2}}
      onClick={e=>{
        setModalExpireSession({isOpen:false})
      }}
      className="absolute px-2 py-2 text-justify cursor-pointer font-semibold text-xs flex z-20 mr-2 right-0 top-0 w-52 rounded-lg bg-ambar-300">
        Su sesion expirara pronto!!
      </motion.div>:
      null}
      <CalendarModal openmodal={openModalCalendar} closemodal={setOpenModalCalendar}/>
      <LoadingModal config={modalLoading} >
        <LoadingModal.Body>
          <LoadingModal.Title>Cambiando Contraseña</LoadingModal.Title>
        </LoadingModal.Body>
      </LoadingModal>



      <Modalsuccess config={modalSuccessConfig}>
        <Modalsuccess.Message title={"Éxito"}>
          {modalSuccessConfig.message}
        </Modalsuccess.Message>
        <Modalsuccess.Buttons>
          <button
            type="button"
            className="btn-gray2 "
            onClick={closeModalsuccess}
          >
            Cerrar
          </button>
        </Modalsuccess.Buttons>
      </Modalsuccess>

      <Modaldanger config={modaldangererrorConfig} front="z-50" tab="300" closeModal={closeModaldangererror}>
        <Modaldanger.Message title={modaldangererrorConfig?.title}>
          {modaldangererrorConfig.message}
        </Modaldanger.Message>
        <Modaldanger.Buttons>
          <button
            type="button"
            tabIndex="301"
            name="modaldanger"
            className="btn-gray2"
            onClick={closeModaldangererror}
          >
            Cerrar
          </button>
        </Modaldanger.Buttons>
      </Modaldanger>

      {modalReports?.isOpen?<ModalReportes modal={modalReports} closeModal={closeModalReports}/>:""}
      {modalShortId.isOpen?
        <Modalgeneric h="size-h-30" w="size-w-30" closeModal={closemodalShortId} config={modalShortId} front={"z-20"}  >
        <Modalgeneric.Body bg="bg-bluei text-white" >
          <div className="flex flex-col gap-2 items-center h-full">
            <label className="font-bold text-lg">Convert Id</label>
            <input key={"iddb"} type="text" autoComplete="off" className="border-form" ref={inputShortId}/>
            
            <div className="flex flex-wrap w-full items-center justify-center gap-2">
              <label >{"Date to ShortId"}</label>
              <Toggle name="activo" checked={shortId} onClick={(e,isOn)=>{
                  setShortId(isOn);
              }}/>
            </div>
            <p>{id}</p>
            
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons bg="bg-bluei text-white">
          <button
            type="button"
            className="btn-dark bg-teal-500 hover:bg-teal-400"
            tabIndex="201"
            onClick={(e) => {
              if(!inputShortId.current.value){ return;}
              const result = shortId?IdToShortId(inputShortId.current.value.toUpperCase()):shortIdToId(inputShortId.current.value.toUpperCase());
              setId(result);
            }}
          >
            Convertir
          </button>
          <button
            type="button"
            className="btn-gray2 mx-1"
            tabIndex="200"
            onClick={closemodalShortId}
          >
            Cerrar
          </button>
        </Modalgeneric.Buttons>
        </Modalgeneric>
        :null}

        <Modalgeneric h="size-h-30" w="size-w-30" closeModal={closemodalItem} config={modalItem} front={"z-20"}  >
        <Modalgeneric.Body >
          <div className="size-2-none gap-1 items-center font-size text-size w-full">
            <label className="text-size md:text-right">Contraseña Actual: </label>
            <div className=" flex flex-wrap justify-center md:justify-start">
              <input 
              type={(!showPass?"password":"text")} 
              value={password} 
              autoComplete="off"
              className="border-form md:mr-1 w-38 uppercase" 
              ref={node=>{input_pass.current=node}}
              onChange={e=>{
                //git console.log(e.target.value)
                setPassword(e.target.value.toUpperCase());
              }}/>
              <button className="text-gray-800 hover:text-gray-500 text-lg w-4 h-4" onClick={e=>{
                setShowPass(!showPass)
              }}>
                <i className={(showPass?"fas fa-eye-slash":"fas fa-eye")}/>
              </button>
              <span className={(error?"text-red-400 font-semibold text-xs w-full":"hidden")}>La contraseña es incorrecta</span>
            </div>
            <label className="text-size md:text-right">Contraseña Nueva: </label>
            <div className="flex flex-wrap justify-center md:justify-start">
              <input type={(!showPass2?"password":"text")} autoComplete="off" value={password2} className="border-form md:mr-1 w-38 uppercase" onChange={e=>{
                setPassword2(e.target.value.toUpperCase());
              }}/>
              <button className="text-gray-800 hover:text-gray-500 text-lg w-4 h-4" onClick={e=>{
                setShowPass2(!showPass2)
              }}>
                <i className={(showPass2?"fas fa-eye-slash":"fas fa-eye")}/>
              </button>
            </div>
          </div>
        </Modalgeneric.Body>
        <Modalgeneric.Buttons bg="bg-bluei text-white">
          <button
            type="button"
            className="btn-dark bg-yellow-500 hover:bg-yellow-400"
            disabled={(password==""||password2==""?true:false)}
            tabIndex="201"
            onClick={(e) => {
              useGetPersonal(getIdUser(),funcionExito,funcionErrorPersonal);
            }}
          >
            Cambiar Contraseña
          </button>
          <button
            type="button"
            className="btn-gray2 mx-1"
            tabIndex="200"
            onClick={closemodalItem}
          >
            Cerrar
          </button>
        </Modalgeneric.Buttons>
        </Modalgeneric>
        
      <div className="relative w-full flex flex-row gap-2 items-center justify-between h-full " >
        {showNavbar?
        <div className={"flex flex-wrap w-full gap-2 shadow-none rounded items-center justify-evenly "}   id="example-navbar-warning">
          <motion.button
              whileHover={btnScale}
              whileTap={{
                scale: 0.8,
                rotate: 5,
                borderRadius: "100%"
              }}
              onClick={(e) => {
                props.Logueo.nombre
                  ? props.addRuta("menu")
                  : props.addRuta("login");
              }}
              className={ "text-white w-16 lg:w-16 hover:text-blue-300 hover:shadow-white border-none text-base font-bold leading-relaxed inline-block uppercase" }
            >
              <Newlogo corona="#FFFFFF" corona1="#FFFFFF"  text="#FFFFFF" />
              {/*<LogoFestivo corona="#FFFFFF" corona1="#FFFFFF"  text="#FFFFFF"/>*/}
          </motion.button>

          {usuario ? (
                <div className={ "text-white flex gap-2 items-center text-size uppercase  font-bold"}>
                  <motion.button whileHover={btnScale} whileTap={btnTap} 
                  className="text-white w-6 hover:text-ambar-200 " onClick={e=>{
                     openmodalItem();
                  }}>
                    <i className={ "fas fa-user text-xl leading-lg"} />
                  </motion.button>
                  {usuario.split(' ')[0]}
                  <motion.button whileHover={btnScale} whileTap={btnTap} 
                    className={' text-3xl text-white '}
                    onClick={e=>{
                      setOpenModalCalendar(true);
                    }}>
                        <i className='fas fa-calendar-day '></i>
                  </motion.button>
                </div>
                ) : null
              }

            {Sucursal("nombre")? (
                 <p className={ " text-ambar-200 w-60/100 lg:w-77/100 justify-center text-center items-center text-base lg:text-3xl uppercase font-bold cursor-pointer" }  >
                  {Sucursal("nombre")}
                </p> 
            ) : null}
          
          {
          ['menu','login'].includes(rutaActual)?null:
          <motion.button whileHover={btnScale} whileTap={btnTap} 
          className={"text-white "} 
          onClick={e=>{
            props.delRuta();
          }}>
            <i className='fas fa-sign-out-alt fa-flip-horizontal text-2xl  '></i>
          </motion.button>

          }
          {props.Logueo?.alcance?(
            <select
              defaultValue={props.Logueo.sucursal}
              className={"font-semibold text-ambar-200 rounded border border-white uppercase text-base focus:outline-none right-0  "+props.Logueo?.bgcolor}
              ref={input_sucursales}
              readOnly={true}
              onChange={e=>{
                if(e.target.value){ 
                  useSetSucursal(e.target.value.toUpperCase(),(respuesta)=>{
                    props.setDataSucursal(respuesta)
                  },funcionError);
                }
              }}>
                  {Object.values(props.Logueo.alcance).sort().map((item,index)=>(
                      <option key={index} defaultValue={item}>{item}</option>
                  ))}
            </select>):null
          }

        </div>
        :null}
        <motion.button
          className="h-full flex justify-items-start md:items-center cursor-pointer text-xl leading-none rounded outline-none focus:outline-none"
          type="button"
          whileHover={btnScale}
          whileTap={btnScale8}
          onClick={() => setNavbarOpen(!navbarOpen)}
          >
              <i className={"text-white fas fa-bars"}></i>
          </motion.button>

        {
            props.Logueo.windowSize<768?
            (<motion.button
              className="h-full flex justify-items-start md:items-center cursor-pointer text-xl leading-none rounded bg-transparent  outline-none focus:outline-none"
              type="button"
              whileHover={btnScale}
              whileTap={btnScale8}
              onClick={() => setShowNavbar(!showNavbar)}
              >
                  <i className={"text-white "+(showNavbar?"fas fa-caret-up":"fas fa-caret-down")}></i>
              </motion.button>
            )
            :null
        }
        {navbarOpen&&usuario?
        <motion.ul 
        animate={navbarOpen?"open":"closed"}
        variants={variants}
        transition={{ duration: 0.4 }}
        className={"absolute z-40 right-0 top-0 mt-11 rounded-lg font-medium leading-6 cursor-pointer "}
        >
          {permisoVender?
            <motion.li 
            className={"flex flex-row justify-between items-center px-1 gap-1 rounded-lg shadow hover:bg-blue-800 text-white mb-1 "+props.Logueo?.bgcolor} 
            whileHover={btnScale}
            whileTap={btnScale8}
            title="Presiona 1"
              onClick={e=>{
                props.addRuta("venta");
                setNavbarOpen(!navbarOpen);
              }
            }>
              <i className="fas fa-hand-holding-usd text-white"></i>
              {"Ventas"}
            </motion.li>
            :null
          }
          {permisoAltaCliente?
            <motion.li 
            className={"flex  flex-row justify-between items-center px-1 gap-1 rounded-lg shadow hover:bg-blue-800 text-white mb-1 "+props.Logueo?.bgcolor} 
            whileHover={btnScale}
            whileTap={btnScale8}
            title="Presiona 2"
              onClick={e=>{
                props.addRuta("clientes");
                setNavbarOpen(!navbarOpen);
              }
            }>
              <i className="fas fa-user-plus text-white"></i>
              {"Cliente"}
            </motion.li>
            :null
          }
          {permisoAltaProveedor?
            <motion.li 
            className={"flex  flex-row justify-between items-center px-1 gap-1 rounded-lg shadow hover:bg-blue-800 text-white mb-1 "+props.Logueo?.bgcolor}
            whileHover={btnScale}
            whileTap={btnScale8}
            title="Presiona 3"
              onClick={e=>{
                props.addRuta("proveedor");
                setNavbarOpen(!navbarOpen);
              }
            }>
              <i className="fas fa-user-tie text-white"></i>
              {"Proveedor"}
            </motion.li>
            :null
          }
          {permisoAltaPersonal?
            <motion.li 
            className={"flex  flex-row justify-between items-center px-1 gap-1 rounded-lg shadow hover:bg-blue-800 text-white mb-1 "+props.Logueo?.bgcolor}
            whileHover={btnScale}
            whileTap={btnScale8}
            title="Presiona 4"
              onClick={e=>{
                props.addRuta("personal");
                setNavbarOpen(!navbarOpen);
              }
            }>
              <i className="fas fa-address-card text-white"></i>
              {"Personal"}
            </motion.li>
            :null
          }
          {permisoReporte?
            <motion.li 
            className={"flex  flex-row justify-between items-center px-1 gap-1 rounded-lg shadow hover:bg-blue-800 text-white mb-1 "+props.Logueo?.bgcolor}
            whileHover={btnScale}
            whileTap={btnScale8}
            title="Presiona 5"
              onClick={e=>{
                setNavbarOpen(!navbarOpen);
                openmodalReports();
              }
            }>
              <i className="fas fa-chart-bar text-white"></i>
              {"Reportes"}
            </motion.li>
            :null
          }
          {permisoSuper?
            <motion.li 
            className={"flex  flex-row justify-between items-center px-1 gap-1 rounded-lg shadow hover:bg-blue-800 text-white mb-1 "+props.Logueo?.bgcolor}
            whileHover={btnScale}
            whileTap={btnScale8}
              onClick={e=>{
                setNavbarOpen(!navbarOpen);
                openmodalShortId();
              }
            }>
              <i className="fas fa-language text-white"></i>
              {"ShortId"}
            </motion.li>
            :null
          } 
          <motion.li 
          className={"flex flex-row  justify-between items-center px-1 gap-1 rounded-lg shadow hover:bg-blue-800 text-red-200 mb-1 "+props.Logueo?.bgcolor }
          whileHover={btnScale}
          whileTap={btnScale8}
          onClick={logOut}>
            <i className="fas rounded-full fa-times-circle text-red-400 bg-white"></i>
            {"Cerrar sesión"}
          </motion.li>
        </motion.ul>
        :null
      }
      </div>
    </nav>
  );
}

const mapDispatchToProps = (dispatch) => ({
  addRuta: (ruta) => dispatch(setRuta(ruta)),
  delRuta:()=>dispatch(returnRuta()),
  setLogueo: (datos) => dispatch(setLogueo(datos)),
  setDataSucursal: (datos) =>dispatch({ type: "SET_DATASUCURSAL", datasucursal: datos, }),

  resetitemsVenta:()=>dispatch({type:"RESET_ITEM_VENTA"}),
  resetClientes:()=>dispatch({type:"CLEAN_CLIENT"}),
  resetClientesVenta:()=>dispatch({type:"CLEAN_CLIENT_VENTA"}),
  resetSearchPersonal:()=>dispatch({type:"RESET_SEARCHPERSONAL"}),
  resetSearchProveedor:()=>dispatch({type:"RESET_SEARCHPROVEEDOR"}),
  resetHeaderCompra:()=>dispatch({type:"CLEAN_HEADERCOMPRA"}),
  resetitemsCompra:()=>dispatch({type:"CLEAN_ITEMCOMPRA"}),
  resetHeaderVenta:()=>dispatch({type:"CLEAN_HEADER_VENTA"}),
  resetVentaAPagar:()=>dispatch({type:"RESET_VENTAPAGAR"}),
  resetTicket:()=>dispatch({type:"RESET_TICKET"}),
  resetventasCredito:()=>dispatch({type:"RESET_VENTACREDITO"}),
  resetInventario:()=>dispatch({type:"RESET_INVENTARIO"}),
  resetPropsInventario:()=>dispatch({type:"CLEAN_ALL_PROPSINVENTARIO"}),
  resetHeaderPes:()=>dispatch({type:"RESET_HEADER_PES"}),
  resetitemsPedidoEspecial:()=>dispatch({type:"RESET_ITEMS_ESP"}),
  resetitemsCash:()=>dispatch({type:"RESET_ITEMS_CASH"}),
  resetPropsReportes:()=>dispatch({type:"RESET_REP"}),
  resetDatos:()=>dispatch({type:"RESET_DATOS"}),
  setChange:(change)=>dispatch({type:"SET_CHANGE",change:change}),
});

const mapStateToProps = (state) => ({
  Logueo: state.Logueo,
  Datasucursal: state.Datasucursal,
  Ruta: state.Ruta,
  PropsReportes: state.PropsReportes,
  Change: state.Cambio,
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
